export const sleepSoundsData = [
    {
      title: "Healing Music",
      imageUrl: "images/RelaxingSleep.png",
      amazonUrl: "amzn1.ask.skill.ce9f2c42-2587-4ddd-b325-13ac966c4dfd",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/956280550-relaxing-sleep-sounds-reclaim-relaxing-soundscapes-relaxing-sleep-sounds.mp3",
    },
    {
      title: "Thunderstorms",
      imageUrl: "images/NewThunder.png",
      amazonUrl: "amzn1.ask.skill.149d3b8b-dba7-40c5-825b-71672ce0109a",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/745129018-relaxing-sleep-sounds-heavythunder5half.mp3",
    },
    {
      title: "Ocean",
      imageUrl: "images/Ocean.png",
      amazonUrl: "amzn1.ask.skill.219e868a-2548-4832-98ee-c6e7ac0b80f5",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/1400637448-relaxing-sleep-sounds-ocean10.mp3",
    },
    {
      title: "Babbling Brook",
      imageUrl: "images/NewBabblingBrook.png",
      amazonUrl: "amzn1.ask.skill.ee6a1994-0ec9-4b61-a19a-347e94f903d9",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/800492440-relaxing-sleep-sounds-forest-brook.mp3",
    },
    {
      title: "Underwater",
      imageUrl: "images/Underwater.png",
      amazonUrl: "amzn1.ask.skill.c56233da-4698-438a-a2d8-1e42b4c66c5f",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/1430622583-relaxing-sleep-sounds-underwater5.mp3",
    },
    {
      title: "Waterfall",
      imageUrl: "images/Waterfall.png",
      amazonUrl: "amzn1.ask.skill.ac5af294-4529-4f09-aee6-d724e14529c5",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/801982114-relaxing-sleep-sounds-waterfall5half.mp3",
    },
    {
      title: "Rain",
      imageUrl: "images/NewRain.png",
      amazonUrl: "amzn1.ask.skill.827d9147-938a-4840-8c03-1ad6b787cbcc",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/744177628-relaxing-sleep-sounds-rain5half.mp3",
    },
    {
      title: "Box Fan",
      imageUrl: "images/Fan.png",
      amazonUrl: "amzn1.ask.skill.0e286a50-4ff5-4ba1-8b85-e3e6c6e6b443",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/753088675-relaxing-sleep-sounds-fan5half.mp3",
    },
    {
      title: "Air Conditioner",
      imageUrl: "images/ACUnit.png",
      amazonUrl: "amzn1.ask.skill.3c1282b0-c5cd-4a66-b750-a7dbacbd143e",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/802237534-relaxing-sleep-sounds-air-conditioner-hum.mp3",
    },
    {
      title: "White Noise",
      imageUrl: "images/WhiteNoise.png",
      amazonUrl: "amzn1.ask.skill.f6269d31-193e-4b37-b50c-2c830ce34a03",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/741659656-relaxing-sleep-sounds-whitenoise5.mp3",
    },
    {
      title: "Brown Noise",
      imageUrl: "images/BrownNoise.png",
      amazonUrl: "amzn1.ask.skill.c5f632e0-dd6a-471f-996c-ceb0794d42a7",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/756463987-relaxing-sleep-sounds-brownnoise5half.mp3",
    },
    {
      title: "Blue Noise",
      imageUrl: "images/BlueNoise.png",
      amazonUrl: "amzn1.ask.skill.57d44297-d54e-4127-9348-0e503b3c34d0",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/746581540-relaxing-sleep-sounds-blue5.mp3",
    },
    {
      title: "Pink Noise",
      imageUrl: "images/PinkNoise.png",
      amazonUrl: "amzn1.ask.skill.bb647e24-e5be-41f6-ab57-b258311bcd30",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/745023763-relaxing-sleep-sounds-pink5.mp3",
    },
    {
      title: "Wind in Trees",
      imageUrl: "images/NewWindInTreet.png",
      amazonUrl: "amzn1.ask.skill.fe756158-b9f6-44d3-8c6f-4038ae351ffc",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/774058069-relaxing-sleep-sounds-windtrees5.mp3",
    },
    {
      title: "Fireplace",
      imageUrl: "images/Fireplace.png",
      amazonUrl: "amzn1.ask.skill.84fc14e8-e84d-4c89-b682-500bde7ef231",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/801762700-relaxing-sleep-sounds-fireplace5.mp3",
    },
    {
      title: "Wake up Sounds",
      imageUrl: "images/RelaxingSleep.png",
      amazonUrl: "amzn1.ask.skill.d786c1a5-356d-4705-94a3-4dff54a78e84",
      soundCloudUrl: "https://feeds.soundcloud.com/stream/1425050560-relaxing-sleep-sounds-birdsareback2.mp3",
    }
  ];
