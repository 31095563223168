import React from 'react';

export default function Footer() {
    return <div className='footer footer-wrapper'>
<div className='desktop'>
<div className='row'>
    <div className='column'>
<h1>How to use these sounds:</h1>
                <ol>
                    <li>
                        <strong>Enable your chosen sound:</strong> Click the "Enable on Amazon" button for the sound you'd like to use or say the full name of the sound (e.g., "Open Relaxing Sleep Sounds: Rain Sounds") to ensure you enable the correct one.
                    </li>
                    <li>
                        <strong>Configure settings:</strong> Opt to try the free trial of the Gold Edition if desired, and then go through the settings to customize your preferences, such as looping and wakeup sound options. You can access the settings again later by saying "Alexa, ask Rain Sounds to open settings" (or replace "Rain Sounds" with the name of your specific sound).
                    </li>
                    <li>
                        <strong>Relax and enjoy:</strong> We won't bother you with upsells – each time you open the sound, it will directly play your chosen sound!
                    </li>
                </ol>

                <h1 className='footer'>What commands can I use with these sounds?</h1>
                <ul>
                    <li>
                        "Alexa, ask Rain Sounds to open settings" (enables you to turn looping on/off, set preferences with the Gold Edition such as wake-up time and wake sound)
                    </li>
                    <li>
                        "Alexa, ask Rain Sounds to upgrade" (available in the US/UK, starts a 31-day free trial of the Gold Edition)
                    </li>
                    <li>
                        "Alexa, ask Rain Sounds to cancel subscription"
                    </li>
                </ul>
                </div>

<div className='column'>
<h1>Are these sounds really free?</h1>
                <p>Yes, they are! Relaxing Sleep Sounds offers 5-hour audio files that can loop for free. There's also a paid version available, which includes a 31-day free trial, followed by a $0.99 - $2.49/month charge (20% discount for Prime Members). Unlike most other sleep sound sounds that provide only 1-hour audio and charge for 4-hour upgrades, our paid version offers 12-hour audio files and an amazing feature called Wake Up Sounds.</p>

                <h1 className='footer'>What other ways can I listen to these incredible sounds?</h1>
                <p>Relaxing Sleep Sounds are currently available on Amazon Alexa and our website. We're also working on making them accessible on all major music streaming services, including Spotify, Amazon Music, and Apple Music, as well as Android and iOS platforms.</p>

                <h1 className='footer'>What are Wake Up Sounds, and why should I consider them?</h1>
                <p>Wake Up Sounds are a paid feature of the above sounds, such as birds chirping in a forest in Germany, church bells, or a babbling brook. Many of the sounds above can transition from your ambient sound to a Wake Up Sound when it's time to rise, allowing you to wake up more peacefully. They're a fantastic way to start your day.</p>

            </div>

            </div>

</div>

<div className='mobile'>
<h2>How to use these sounds:</h2>
                <ol>
                    <li>
                        <strong>Enable your chosen sound:</strong> Click the "Enable on Amazon" button for the sound you'd like to use or say the full name of the sound (e.g., "Open Relaxing Sleep Sounds: Rain Sounds") to ensure you enable the correct one.
                    </li>
                    <li>
                        <strong>Configure settings:</strong> Opt to try the free trial of the Gold Edition if desired, and then go through the settings to customize your preferences, such as looping and wakeup sound options. You can access the settings again later by saying "Alexa, ask Rain Sounds to open settings" (or replace "Rain Sounds" with the name of your specific sound).
                    </li>
                    <li>
                        <strong>Relax and enjoy:</strong> We won't bother you with upsells – each time you open the sound, it will directly play your chosen sound!
                    </li>
                </ol>

                <h2 className='footer'>What commands can I use with these sounds?</h2>
                <ul>
                    <li>
                        "Alexa, ask Rain Sounds to open settings" (enables you to turn looping on/off, set preferences with the Gold Edition such as wake-up time and wake sound)
                    </li>
                    <li>
                        "Alexa, ask Rain Sounds to upgrade" (available in the US/UK, starts a 31-day free trial of the Gold Edition)
                    </li>
                    <li>
                        "Alexa, ask Rain Sounds to cancel subscription"
                    </li>
                </ul>

                <h2>Are these sounds really free?</h2>
                <p>Yes, they are! Relaxing Sleep Sounds offers 5-hour audio files that can loop for free. There's also a paid version available, which includes a 31-day free trial, followed by a $0.99 - $2.49/month charge (20% discount for Prime Members). Unlike most other sleep sound sounds that provide only 1-hour audio and charge for 4-hour upgrades, our paid version offers 12-hour audio files and an amazing feature called Wake Up Sounds.</p>

                <h2 className='footer'>What other ways can I listen to these incredible sounds?</h2>
                <p>Relaxing Sleep Sounds are currently available on Amazon Alexa and our website. We're also working on making them accessible on all major music streaming services, including Spotify, Amazon Music, and Apple Music, as well as Android and iOS platforms.</p>

                <h2 className='footer'>What are Wake Up Sounds, and why should I consider them?</h2>
                <p>Wake Up Sounds are a paid feature of the above sounds, such as birds chirping in a forest in Germany, church bells, or a babbling brook. Many of the sounds above can transition from your ambient sound to a Wake Up Sound when it's time to rise, allowing you to wake up more peacefully. They're a fantastic way to start your day.</p>


</div>

<p className='footer'>Questions or feedback? Contact me: <a href="mailto:relaxingsleep.alexa@gmail.com">
                    RelaxingSleep.Alexa@gmail.com</a></p>
                <p>© 2023 Copyright: RelaxingSleepSounds.com</p>

</div>
}
