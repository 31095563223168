import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { sleepSoundsData } from './constants';
import Footer from './components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';


ReactGA.initialize('G-CLXL5GWTJY');
ReactGA.send({ hitType: "pageview", page: "/", title: "Relaxing Sleep Sounds" });


function App() {

  const [playing, setPlaying] = useState([])

  const handlePlay = (title) => {
    const audioElement = document.getElementById(title);
    if (audioElement && audioElement.paused){
      document.querySelectorAll('audio').forEach(el => el.pause());
      audioElement.play()
      ReactGA.event({
        category: 'Audio',
        action: 'Play',
        label: title
      });
      setPlaying([title])
    } else if (audioElement) {
      audioElement.pause();
      ReactGA.event({
        category: 'Audio',
        action: 'Pause',
        label: title
      });
      setPlaying(playing.filter(item => item !== title))
    }
  }

  const handleSkillNavigation = (skillId, title) => {
    ReactGA.event({
      category: 'Navigate',
      action: 'Skill Launch',
      label: title
    });
    window.location.href = `https://alexa-skills.amazon.com/apis/custom/skills/${skillId}/launch`
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Relaxing Sleep Sounds</title>
        <meta
          name="description"
          content="Explore soothing sleep sounds for Alexa with our ambient noise app - featuring rain, white noise, and other nature sounds to help you relax, focus, or drift off to sleep. Preview and enjoy our extensive collection of sounds today!"
        />;
        <meta
          name="twitter:title"
          content="Relaxing Sleep Sounds - Ambient sounds to help you sleep, relax, and focus."
        />
        <meta
          name="twitter:description"
          content="Immerse yourself in tranquility with Relaxing Sleep Sounds, your go-to source for high-quality ambient sounds designed to ease your mind and lull you into a peaceful slumber. Our curated collection caters to all tastes, featuring calming rain, gentle ocean waves, soothing forest sounds, and more. Experience serene, rejuvenating nights with Relaxing Sleep Sounds on Amazon Alexa or our website."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="www.relaxingsleepsounds.com" />
        <meta
          property="og:title"
          content="Relaxing Sleep Sounds - Ambient sounds to help you sleep, relax, and focus."
        />
        <meta
          property="og:description"
          content="Immerse yourself in tranquility with Relaxing Sleep Sounds, your go-to source for high-quality ambient sounds designed to ease your mind and lull you into a peaceful slumber. Our curated collection caters to all tastes, featuring calming rain, gentle ocean waves, soothing forest sounds, and more. Experience serene, rejuvenating nights with Relaxing Sleep Sounds on Amazon Alexa or our website."
        />
        <meta
          property="og:image"
          content="https://relaxingsleepsounds.com/images/RelaxingSleep.png"
        />
      </Helmet>
      <div className="App">
        <Container>
            <h1 className='header'>Relaxing Sleep Sounds for Amazon Alexa</h1>
            <p className='secondary'>5 HOUR LOOP FOR FREE</p>
            <p className='desktop'>Immerse yourself in tranquility with Relaxing Sleep Sounds, your go-to source for high-quality ambient sounds designed to ease your mind and lull you into a peaceful slumber. Our curated collection caters to all tastes, featuring calming rain, gentle ocean waves, soothing forest sounds, and more. Experience serene, rejuvenating nights with Relaxing Sleep Sounds on Amazon Alexa or our website.</p>
            <p className='mobile'>Discover peace using Relaxing Sleep Sounds, your top ambient noise source for restful sleep. Enjoy calming rain, ocean waves, and forest sounds on Alexa or our site.</p>
          <Row>
            {sleepSoundsData.map((sound, index) => (
              <Col key={index} xs={6} sm={6} md={6} lg={4} xl={3} className="d-flex justify-content-center">
                <Card className={playing.includes(sound.title) ? "active" : ""}>
                  <Card.Img alt={sound.title + " logo"} variant="top" src={sound.imageUrl} />
                  <Card.Body>
                    <Card.Title><h1 className='theTitle'>{sound.title}</h1></Card.Title>
                    <div className='line-it'>
                    <Button onClick={() => handleSkillNavigation(sound.amazonUrl, sound.title)}>Try on Alexa</Button>

                    <audio id={sound.title} src={sound.soundCloudUrl} preload='none' loop></audio>
                    <div>
                    <Button onClick={() => handlePlay(sound.title)}>
                      Sample
                      <FontAwesomeIcon
                        icon={playing.includes(sound.title) ? faPause : faPlay}
                        className={playing.includes(sound.title) ? "playing controls": "paused controls"}
                      />
                    </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <Footer/>

        </Container>
      </div>
    </HelmetProvider>
  );
}

export default App;
